<template>
  <div>
    <important-indicator
      :list="keymetricList"
      :pathday="pathDay"
      @on-date-change="onDateChange"
    />
    <b-card no-body>
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
          style="gap: 8px;"
        >
          <div>
            <h4>{{ $t('List of combo') }}</h4>
            <div>{{ $t('Create combos with attractive offers that will attract more buyers!') }}</div>
          </div>
          <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="/bundle/add"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('Create new') }}
          </b-button>
        </div>

        <div class="filter-tab w-100">
          <div
            v-for="item in filterTabs"
            :key="item.id"
            :class="['filter-tab__item', {'active': filterSelected == item.id}]"
            @click="filterSelected = item.id"
          >
            {{ $t(item.label) }}
          </div>
        </div>
      </b-card-header>

      <b-card-body>
        <!-- <CustomTable
          :items="items"
          :fields="fields"
          @handlerSort="handlerSort"
          @handlerDelete="handlerDelete"
        /> -->

        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('Search') }}</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('Search')"
                type="text"
                class="d-inline-block"
                style="width: 220px;"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('Discount promotion period') }}</label>
              <flat-pickr
                v-model="rangeDate.string"
                class="form-control"
                style="width: 220px;"
                :config="{ mode: 'range', dateFormat: 'd-m-Y' }"
                :placeholder="$t('From - To date')"
                @on-close="onDatePicker"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          ref="promotion-products"
          class="custom-good-table"
          :columns="columns"
          :rows="filterItems"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <!-- Table: Head -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>

          <!-- Table: Rows -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <div
              v-if="props.column.field === 'name'"
              style="max-width: 200px;"
            >
              <span>{{ props.row.name }}</span>
            </div>

            <!-- Column: Products -->
            <span
              v-else-if="props.column.field === 'products'"
            >
              <div
                class="d-flex flex-wrap align-items-center justify-content-center"
                style="gap: 6px; min-width: 120px;"
              >
                <b-avatar
                  v-for="item in props.row.product"
                  :key="item.avatar"
                  :src="item.avatar"
                  variant="light-info"
                  rounded
                />
              </div>
            </span>

            <!-- Column: Combo Period -->
            <div
              v-if="props.column.field === 'combo_period'"
              class="text-center mx-auto"
              style="max-width: 170px;"
            >{{ props.row.date_from }} <br> {{ props.row.date_to }}</div>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                class="d-block"
                :variant="statusVariant(props.row.status)"
              >
                {{ $t(props.row.status) }}
              </b-badge>
            </span>

            <!-- Column: Actions -->
            <div
              v-else-if="props.column.field === 'actions'"
              class="text-center"
            >
              <!-- <router-link :to="`/combo/edit/${props.row.id}`">{{ $t('Detail') }}</router-link> -->
              <b-button
                v-if="props.row.status == 'Upcoming'"
                variant="flat-dark"
                class="text-black btn-icon rounded-circle"
                :to="`/bundle/edit/${props.row.id}`"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-if="props.row.status != 'Upcoming'"
                variant="flat-dark"
                class="text-black btn-icon rounded-circle"
                :to="`/bundle/edit/${props.row.id}`"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-if="props.row.status != 'Ongoing'"
                variant="flat-danger"
                class="text-danger btn-icon rounded-circle"
                @click="handlerDelete(props.row.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','30','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>
<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BPagination,
  BBadge,
} from 'bootstrap-vue'

import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import ImportantIndicator from '../../components/custom-component/important-indicator.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    ImportantIndicator,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BBadge,
    flatPickr,
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      rangeDate: {
        string: null,
        from: null,
        to: null,
      },
      fields: [
        { key: 'name', label: 'Promotion name', sortable: false },
        { key: 'product', label: 'Products', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'totaluse', label: 'Used', sortable: false },
        { key: 'dateAndStatus', label: 'Application date', sortable: false },
        { key: 'more', label: 'Tool', sortable: false },
        // { key: 'sort', label: 'Reorder', sortable: false },
      ],
      items: [],
      keymetricList: [
        {
          id: 1,
          status: 0,
          text: 'Turnover',
          value: 9,
          percent: -12,
          description: 'description.',
          is_price: 1,
          is_rate: 0,
        },
        {
          id: 2,
          status: 1,
          text: 'Order',
          value: 6,
          percent: 76,
          description: 'description.',
          is_price: 0,
          is_rate: 0,
        },
        {
          id: 3,
          status: 0,
          text: 'Usage rate',
          value: 2,
          percent: -10,
          description: 'description.',
          is_price: 0,
          is_rate: 0,
        },
        {
          id: 4,
          status: 0,
          text: 'Buyer',
          value: 1230,
          percent: 67,
          description: 'description.',
          is_price: 0,
          is_rate: 0,
        },
      ],
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      pageLength: 10,
      searchTerm: '',
      columns: [
        { field: 'name', label: 'Combo name', sortable: true },
        { field: 'products', label: 'Products', sortable: false },
        { field: 'status', label: 'Status', sortable: true },
        { field: 'combo_period', label: 'Promotion period', sortable: false },
        { field: 'actions', label: 'Actions', sortable: false },
      ],
      filterSelected: 'all',
      filterTabs: [
        {
          id: 'all',
          label: 'All',
        },
        {
          id: 'Ongoing',
          label: 'In progress',
        },
        {
          id: 'Upcoming',
          label: 'Upcoming',
        },
        {
          id: 'expired',
          label: 'Finished',
        },
      ],
      statisticParams: {
        date_from: null,
        date_to: null,
        status: null,
      },
      promotionPrams: {
        date_from: null,
        date_to: null,
      },
      pathDay: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Ongoing : 'light-success',
        Upcoming     : 'light-info',
        Expired     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 'all') return this.items
      return this.items.filter(item => item.status.toLowerCase() === this.filterSelected.toLowerCase())
    },
  },
  mounted() {
    this.loadList()
    this.getStatistic()
  },
  methods: {
    async handlerSort(data) {
      const { item } = data
      const { method } = data
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [item.item.id, this.items[item.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [item.item.id, this.items[item.index + 1].id]
        }
        if (method === 'change') {
          params.type = 2
          params.ids = [
            {
              id: item.item.id,
              position: Number(item.item.position),
            },
          ]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/bundled/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        let strParams = ''
        Object.keys(this.promotionPrams).map(isKey => {
          if (this.promotionPrams[isKey]) {
            strParams += `&${isKey}=${this.promotionPrams[isKey]}`
          }
          return true
        })
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/v2/bundleds?site_id=${siteId}${strParams}&sort[]=id,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = await this.getDataTranslation(
              res.data.data.items,
              true,
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/v2/bundled/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Promotion has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async getStatistic() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        let strParams = ''
        Object.keys(this.statisticParams).map(isKey => {
          if (this.statisticParams[isKey]) {
            strParams += `&${isKey}=${this.statisticParams[isKey]}`
          }
          return true
        })
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/v2/bundled_statistic?site_id=${siteId}${strParams}&sort[]=id,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.keymetricList[0].value = this.unitFormatOriginal(res.data.data.turnover)
            this.keymetricList[1].value = res.data.data.order_count
            this.keymetricList[2].value = `${res.data.data.usage_rate}%`
            this.keymetricList[3].value = res.data.data.customer_count
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    onDateChange(dateFrom, dateTo) {
      const arrDateFrom = dateFrom.split('-')
      const arrDateTo = dateTo.split('-')
      this.statisticParams.date_from = `${arrDateFrom[2]}-${arrDateFrom[1]}-${arrDateFrom[0]}`
      this.statisticParams.date_to = `${arrDateTo[2]}-${arrDateTo[1]}-${arrDateTo[0]}`

      const datef = new Date(this.statisticParams.date_from)
      const datet = new Date(this.statisticParams.date_to)
      const secondsf = datef.getTime() / 1000
      const secondst = datet.getTime() / 1000
      const tempPathDay = secondst - secondsf
      if (tempPathDay > 0) {
        this.pathDay = ((tempPathDay / 60) / 60) / 24
      }
      this.getStatistic()
    },
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        const dateFrom = dateStr.split(' ')[0]
        const dateTo = dateStr.split(' ')[2]
        const arrDateFrom = dateFrom.split('-')
        const arrDateTo = dateTo.split('-')
        this.promotionPrams.date_from = `${arrDateFrom[2]}-${arrDateFrom[1]}-${arrDateFrom[0]}`
        this.promotionPrams.date_to = `${arrDateTo[2]}-${arrDateTo[1]}-${arrDateTo[0]}`
        this.loadList()
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
</style>

<style lang="sass" scoped>
.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>
